import { defineEnvConfig } from '../defineEnvConfig'

export function createStagingConfig(isCn: boolean) {
  return defineEnvConfig({
    auth0Url: 'https://uat-christies-account.auth0.com',
    auth0Audience: 'https://uat.christies.com/general-service-api',
    cookieDomain: `.christies.com${isCn ? '.cn' : ''}`,
    dotComUrl: `https://staging.christies.com${isCn ? '.cn' : ''}`,
    dslAssetsUrl: `https://dsl.assets.christies.com${isCn ? '.cn' : ''}`,
    thisAppUrl: `https://signup-uat.christies.com${isCn ? '.cn' : ''}`,
    adobeContainerUrl:
      '//assets.adobedtm.com/4f105c1434ad/05fddbc767bb/launch-334804b1bc3f-staging.min.js',
    afdUrl: `https://api-nonprod.christies.com${isCn ? '.cn' : ''}/uat`,
    apimUrl: 'https://devapim.christies.com/uat',
    apiUrl: 'https://stgapi.christies.com',
  })
}
