import Header, { type HeaderData } from '@christies/header'
import { ShadowDomWrapper } from 'components/ShadowDomWrapper'
import { environment } from 'configuration'
import { languageMapper, SupportedLanguages } from 'configuration/translations'
import { useDataLoader } from 'hooks/useDataLoader'
import { isChineseRegion } from 'utils/getRegion'

export type MaximalHeaderProps = {
  onError: (url: string) => void
  language: SupportedLanguages
}

export function MaximalHeader({ onError, language }: Readonly<MaximalHeaderProps>) {
  const data = useDataLoader<HeaderData>({
    onError,
    url: `${environment.apiUrl}/header-footer-content/header?urlFormat=absolute&cnDomain=${isChineseRegion() ? 'true' : 'false'}&language=${languageMapper(language)}`,
  })

  return (
    <ShadowDomWrapper id="headerHost" stylesUrl="/styles/header/index.css">
      {data ? <Header headerData={data} useLegacyLanguageSwitcher /> : null}
    </ShadowDomWrapper>
  )
}
