import NewFooter, { type FooterData } from '@christies/footer'
import { ShadowDomWrapper } from 'components/ShadowDomWrapper'
import { environment } from 'configuration'
import { useDataLoader } from 'hooks/useDataLoader'
import { languageMapper, SupportedLanguages } from 'configuration/translations'
import { isChineseRegion } from 'utils/getRegion'

type MaximalFooterProps = {
  onError: (url: string) => void
  language: SupportedLanguages
}

const MaximalFooter = ({ onError, language }: MaximalFooterProps) => {
  const data = useDataLoader<FooterData>({
    onError,
    url: `${environment.apiUrl}/header-footer-content/footer?urlFormat=absolute&cnDomain=${isChineseRegion() ? 'true' : 'false'}&language=${languageMapper(language)}`,
  })

  return (
    <>
      {/* https://christiestech.atlassian.net/wiki/spaces/CT/pages/3698229332/ASP.NET+or+non-React+NextJS+Applications#Cookie-settings-modal */}
      <button id="cookie-settings-shadow-dom" className="ot-sdk-show-settings" hidden />
      <ShadowDomWrapper id="footerHost" stylesUrl="/styles/footer/index.css">
        {data ? <NewFooter footerData={data} /> : null}
      </ShadowDomWrapper>
    </>
  )
}

const MinimalFooter = () => null

export enum FooterSize {
  Minimal,
  Maximal,
}

export const Footer = ({
  size,
  ...rest
}: { size: FooterSize } & Pick<MaximalFooterProps, 'onError' | 'language'>): JSX.Element =>
  size === FooterSize.Maximal ? <MaximalFooter {...rest} /> : <MinimalFooter />
