import { defineEnvConfig } from '../defineEnvConfig'

export function createProductionConfig(isCn: boolean) {
  return defineEnvConfig({
    auth0Url: 'https://christies-account.us.auth0.com',
    auth0Audience: 'https://christies.com/general-service-api',
    cookieDomain: `.christies.com${isCn ? '.cn' : ''}`,
    dotComUrl: `https://www.christies.com${isCn ? '.cn' : ''}`,
    thisAppUrl: `https://signup.christies.com${isCn ? '.cn' : ''}`,
    dslAssetsUrl: `https://dsl.assets.christies.com${isCn ? '.cn' : ''}`,
    adobeContainerUrl: '//assets.adobedtm.com/4f105c1434ad/05fddbc767bb/launch-1b477ca5fd6e.min.js',
    tracesSampleRate: 0.5,
    afdUrl: `https://api-prod.christies.com${isCn ? '.cn' : ''}`,
    apimUrl: 'https://apim.christies.com',
    apiUrl: 'https://api.christies.com',
  })
}
